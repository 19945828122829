import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     redirect: "/front/standard/index",
    // },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login/QrCodeLogin.vue"),
    },
    {
        path: "/front",
        name: "front",
        component: () => import("../Front.vue"),
        children: [
            {
                path: "standard/index",
                name: "standard-index",
                component: () =>
                    import("../views/FrontSkin/01-Standard/Index/Index.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    noticeComponent: "standard-notice",
                    noticeProps: {
                        tabDisabled: true,
                    },
                    title: "招聘系统",
                },
            },
            {
                path: "standard/index2",
                name: "standard-index2",
                component: () =>
                    import("../views/FrontSkin/01-Standard/Index/Index.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    noticeComponent: "standard-notice",
                    noticeProps: {
                        tabDisabled: false,
                    },
                },
            },
            {
                path: "standard/index5",
                name: "standard-index5",
                component: () =>
                    import("../views/FrontSkin/01-Standard/Index/Index.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    noticeComponent: "standard-notice-news-list",
                    noticeProps: {},
                },
            },
            {
                path: "standard/index3",
                name: "standard-index3",
                component: () =>
                    import("../views/FrontSkin/01-Standard/Index/Index.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    noticeComponent: "standard-notice-list",
                    noticeProps: {},
                },
            },
            {
                path: "posts",
                name: "front-posts",
                component: () => import("../views/Posts/Posts.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "岗位列表",
                },
            },
            {
                path: "expand-posts",
                name: "expand-posts",
                component: () => import("../views/Posts/ExpandPost.vue"),
                meta: {
                    postConfig: {
                        /** 岗位是否默认展开 */
                        defaultIsExpand: true,
                        expandBehavior: "static",
                        isVague: false,
                    },
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "岗位列表",
                },
            },
            {
                path: "notice",
                name: "front-notice",
                component: () => import("../views/Notice/List.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "招聘公告",
                },
            },
            {
                path: "notice-tab",
                name: "front-notice-tab",
                component: () => import("../views/Notice/TabList.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "招聘公告",
                },
            },
            {
                path: "notice-detail/:id",
                name: "front-notice-detail",
                component: () => import("../views/Notice/Detail.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "招聘公告",
                },
            },
            {
                path: "posts-ceshi",
                name: "front-posts-ceshi",
                component: () => import("../views/Posts/Posts.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "岗位列表",
                },
            },
            {
                path: "front-dimapage/:pageId",
                name: "front-dimapage",
                component: () => import("../views/Dima/Dima.vue"),
                props: true,
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                },
            },
            {
                path: "enterPersonal",
                name: "enterPersonal",
                component: () => import("../views/EnterPersonal/Index2.vue"),
                // props: true,
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "个人中心",
                },
            },
            {
                path: "MsgNotice",
                name: "msgNotice",
                component: () => import("../views/MsgNotice/MsgNotice.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "消息通知",
                },
            },
            {
                path: "DeliverRecord",
                name: "deliverRecord",
                component: () =>
                    import("../views/DeliverRecord/DeliverRecord.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "投岗记录",
                },
            },
            {
                path: "questionsPage",
                name: "questionsPage",
                props: true,
                component: () =>
                    import("../views/QuestionsPage/QuestionsPage.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "留言板",
                },
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.path === "/") {
        const defaultNav = Store.getters?.defaultNav || null;
        if (_.isEmpty(defaultNav)) {
            Store.dispatch("system/getDefaultNav").then((defaultNavInfo) => {
                if (defaultNavInfo) {
                    next(defaultNavInfo.route);
                } else {
                    next({ name: "standard-index" });
                }
            });
        } else {
            next(defaultNav.route);
        }
    } else {
        await Store.dispatch("user/initLoginUser").catch((err) => {
            console.error(err);
        });
    }
    next();
});

router.afterEach((to, from, next) => {
    let title = "";
    if (to.name === "standard-index" || to.name === "standard-index2") {
        title = Store.getters.systemName || to?.meta?.title;
    } else {
        title = Store.getters.pageName || to?.meta?.title || "招聘系统";
    }
    if (title) {
        document.title = title;
    }
});

export default router;
