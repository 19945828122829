/**
 * @description 系统级的相关信息
 * @date 2023-02-08
 * @author maxi
 */

export default {
    namespaced: true,
    state: {
        /** 当前所在页面名称 */
        pageName: "",
        /** 当前系统名称 */
        systemName: "",
        // 当前系统导航列表
        menuTree: [],
        // 默认导航
        defaultNav: [],
        /** 系统参数配置 */
        systemConfig: {},
    },
    mutations: {
        /** 当前页面名称 */
        set_current_page_name(state, pageName) {
            state.pageName = pageName;
        },
        /** 当前系统名称 */
        set_system_name(state, systemName) {
            state.systemName = systemName;
        },
        setMenuTree(state, menuTree) {
            state.menuTree = menuTree;
        },
        setNavDefault(state, defaultNav) {
            state.defaultNav = defaultNav;
        },
        setSystemConfig(state, systemConfig) {
            state.systemConfig = systemConfig;
        },
    },
    actions: {
        /**
         * @description 获取默认导航
         * @returns {Promise}
         */
        getDefaultNav({ commit }) {
            return new Promise((resolve, reject) => {
                if (!kq.Tools.isEmpty(this.state.system.defaultNav)) {
                    resolve(this.state.system.defaultNav);
                }
                Vue.prototype.$japi.menu
                    .getMenuTree("3")
                    .then((data) => {
                        const navList = data
                            .filter((item) => {
                                return item.is_white_menu === 1;
                            })
                            .map((data) => {
                                const name = data?.name || "";
                                const subname = data?.content_desc || "";
                                const route = data?.page?.view || "";
                                const pageName = data?.page?.page_name || "";
                                const pageId = data?.page?.page_id || "";
                                const routeType = data?.content_type || "";
                                const otherRoute = data?.arg0 || "";
                                const result = {
                                    name,
                                    subname,
                                    route: { name: route || "front-dimapage" },
                                    pageId,
                                    routeType,
                                    otherRoute,
                                    defaultNav: data.is_default_menu === 1,
                                    pageName,
                                };
                                if (!route) {
                                    result.route.params = { pageId };
                                }
                                return result;
                            });
                        commit("setMenuTree", navList);
                        let defaultNav = navList.find((item) => {
                            return item.defaultNav;
                        });
                        if (!defaultNav) {
                            defaultNav = navList[0];
                        }
                        commit("set_system_name", defaultNav.pageName);
                        commit("setNavDefault", defaultNav);
                        resolve(defaultNav);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        /**
         * @description 获取系统配置
         * @returns
         */
        getSystemConfig({ commit }) {
            return new Promise((resolve, reject) => {
                if (Object.keys(this.state.system.systemConfig).length > 0) {
					console.log("1")
                    resolve(this.state.system.systemConfig);
                } else {
                    Vue.prototype.$japi.data
                        .getDataListByFormCode("zp_web_config")
                        .then((data) => {
                            const webConfig = data?.datas || [];
                            if (webConfig.length > 0) {
                                const logoUrl = Vue.prototype.$getDataItem(
                                    webConfig[0],
                                    "xuexiaodingbulogo",
                                    "value"
                                );
                                const schoolName = Vue.prototype.$getDataItem(
                                    webConfig[0],
                                    "xxmc",
                                    "value"
                                );
                                const recruitTitle = Vue.prototype.$getDataItem(
                                    webConfig[0],
                                    "xitongmingcheng",
                                    "value"
                                );
                                const recruitTitleDescribe =
                                    Vue.prototype.$getDataItem(
                                        webConfig[0],
                                        "xitongmiaoshu",
                                        "value"
                                    );
                                const iconLink = Vue.prototype.$getDataItem(
                                    webConfig[0],
                                    "wytb",
                                    "value"
                                );
								const remokePostValue = Vue.prototype.$getDataItem(
									webConfig[0],
                                    "sfkqcx",
                                    "value"
                                );
								const remokePostEnabled = kq.Tools.isEqual(remokePostValue, "1");
                                const systemConfig = {
                                    logoUrl,
                                    schoolName,
                                    recruitTitle,
                                    recruitTitleDescribe,
                                    iconLink,
									remokePostEnabled
                                };
                                commit("setSystemConfig", systemConfig);
                                resolve(systemConfig);
                            } else {
                                resolve();
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },
    },
};
